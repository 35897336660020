import React from "react";
import "../css/login.css"
import logo from "../imgs/logobg.png"
import email from "../imgs/email.png";
import phone from "../imgs/phone2.png";
import FormBase from "./formBase";

const Account = () => {

    const Pannel = () => {
        return (
            <div key={10} id="stepTen" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px", display: "flex", alignItems:"center", flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} width={"14%"} height={"50%"} src={logo}/>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px"}}>Account Overview</p>
            </div>
            <div style={{marginTop: "3vh",display: "flex", flexDirection: "column", alignItems: "flex-start", alignSelf: "flex-start"}}>
                <div style={{height: "30%",position: "relative", display: "flex", justifyContent: "center",marginBottom: "7vh"}}>
                    <img style={{position: "absolute",bottom:"-20%",left: "12%",zIndex: "1",display: "flex", justifyContent: "center", alignItems: "center",borderRadius: "150px", width: "8vw", height: "16vh", color: "white", textAlign: "center",border: "5px solid white"}}/>
                    <img style={{borderRadius: "20px", width: "35vw", height: "30vh", color: "white", textAlign: "center",zIndex: "0",marginLeft:"3vw"}}/>
                </div>
                <p style={{fontWeight: "600", letterSpacing: "1px",marginLeft: "5vw",marginTop: "10px"}} className="AccountText">{}</p>
                <p className="AccountText" style={{marginLeft:"5vw",marginTop: "10px"}}>{} {}, {}</p>
                <p className="AccountText" style={{marginLeft:"5vw",marginTop: "10px"}}>{}, {} {}</p>
                <p style={{marginTop: "15px",textAlign: "center", display: "flex", flexDirection: "row", alignItems: "flex-end",marginLeft: "5vw"}} className="AccountText"><img style={{width: "18px", height: "18px", marginRight: "7px"}} src={phone}/>912-123-4567 <img style={{width: "18px", height: "18px",marginLeft: "10px",marginRight: "7px"}} src={email}/>{}</p>
            </div>
            <div style={{width: "110%", height: "2px", backgroundColor: "#8176FF",marginTop: "3vh"}}/>
            <div style={{width: "100%", display: "flex", flexDirection: "column",alignItems: "center",marginTop: "2vh"}}>
            Style
            </div>
            <div style={{width: "100%", display: "flex", flexDirection: "column",alignItems: "center",marginTop: "2vh"}}>
                <div style={{width: "110%", height: "2px", backgroundColor: "#8176FF"}}/>
                <div style={{marginTop: "3vh",display: "flex",flexDirection: "row", justifyContent: "space-evenly"}}>
                    <div style={{width: "40%"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <p style={{marginRight: "10px"}} className="AccountText  BoldSpaced">Bundle Services</p>
                            <label className="switch">
                            <input type="checkbox"/>
                            <span className="slider round"/>
                            </label>
                        </div>
                        <p className="AccountText">This Service is a Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et</p>
                    </div>
                    <div style={{width: "40%"}}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <p style={{marginRight: "10px"}} className="AccountText  BoldSpaced">Subscription Service</p>
                            <label className="switch">
                            <input type="checkbox"/>
                            <span className="slider round"/>
                            </label>
                        </div>
                        <p className="AccountText">This Service is a Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        <div>
                            <p style={{marginTop: "20px"}} className="AccountText BoldSpaced">Frequency</p>
                            <div className="SubscriptionOptions">
                                <p className="AccountText">Every 2-4 weeks</p>
                                <p className="AccountText  BoldSpaced">$910</p>
                            </div>
                            <div className="SubscriptionOptions">
                                <p className="AccountText">Every 6-8 weeks</p>
                                <p className="AccountText  BoldSpaced">$560</p>
                            </div>
                            <div className="SubscriptionOptions">
                                <p className="AccountText">Antime<br/>(12 visits/yr)</p>
                                <p className="AccountText  BoldSpaced">$925</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return(
        <>
        <FormBase formContent={<Pannel/>}/>
        </>
    )
}

export default Account;