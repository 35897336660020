import React, { useReducer } from "react";
import "../css/dashboard.css"
import ParticleBackground from "./particleBackground";
import profile from "../imgs/profile.svg"
import trash from "../imgs/bin-white.png"
import save from "../imgs/floppy-disk-white.png"
import logout from "../imgs/logout.svg"
import home from "../imgs/home.svg"
import services from "../imgs/services.svg"
import logo from "../imgs/logobg.png"
import email from "../imgs/email.png";
import phone from "../imgs/phone2.png";
import { useState } from "react";
import Account from "./account";
import { Link } from "react-router-dom";
import sunflower from '../imgs/sunflowers.jpg'
import rock from '../imgs/therock.jpg'
import usStates from "./states.js";
import { ImageUpload } from "./imageUpload";
import apiRequest from "./api";
import add from "../imgs/add.svg"
import Button from "./button";

const Dashboard = (props) => {
    const userData = JSON.parse(window.localStorage.getItem("porteiro_user_token"));
    props.data.Header.removeHeader(true);

    const AllServices = () => {
        const [services, ChangeServices] = useState();
        const [serviceCategories, ChangeServiceCategories] = useState();
        const [serviceSubCategories, ChangeServiceSubCategories] = useState();
        const [pid, changeId] = useState();
        const [create, changeCreate] = useState(false);

        const getServices = (id) => {
            let headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData.bearer}`
            }
    
            let didGetCategories = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=PartnerServices/${id}`,"GET", headers)
              didGetCategories.then((results) => 
                {
                switch(results.status){
                  case "500-Internal Server Error":
                    
                    break;
                  case "200-OK":
                    //get data from token
                    ChangeServices(results.data);
                    break;
                  default:
                    
                    break;
                }
            }); 
        }

        const getSubCategories = (e) => {
            let headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData.bearer}`
            }
    
            let didGetCategories = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Categories/${e.target.value}/Children`,"GET", headers)
              didGetCategories.then((results) => 
                {
                switch(results.status){
                  case "500-Internal Server Error":
                    
                    break;
                  case "200-OK":
                    //get data from token
                    ChangeServiceSubCategories(results.data);
                    break;
                  default:
                    
                    break;
                }
            }); 
        }

        const tryCreateService = () => {
            let newData = [{
                "brief": document.getElementById("createBrief").value,
                "cost": document.getElementById("createCost").value,
                "duration" : document.getElementById("createDuration").value,
                "categoryServiceId" : document.getElementById("createCategory").value,
                "subCategoryServiceId": document.getElementById("createSubCategory").value,
                "partnerId" : pid,
                "userId" : userData.user.id
            }]
            
    
            let headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization : `Bearer ${userData.bearer}`
            }

            let didCreateServices = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=PartnerServices/Create`,"POST", headers,newData)
            didCreateServices.then((results) => 
                {
                console.log(results);
                switch(results.status){
                  case "500-Internal Server Error":
                    break;

                  case "200-OK":
                    ChangeServices(results.data);
                    break;

                  default:
                    break;
                }
            }); 
        }

        const tryUpdateService = (id) => {
            let newData = {
                "brief": document.getElementById(`serviceBrief${id}`).value,
                "cost": document.getElementById(`serviceCost${id}`).value,
                "duration" : document.getElementById(`serviceDuration${id}`).value,
                "id" : id,
                "partnerId" : pid
            }
            
    
            let headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization : `Bearer ${userData.bearer}`
            }

            let didCreateServices = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=PartnerServices/Update/${id}`,"POST", headers,newData)
            didCreateServices.then((results) => 
                {
                console.log(results);
                switch(results.status){
                  case "500-Internal Server Error":
                    break;

                  case "200-OK":
                    console.log(results);
                    break;

                  default:
                    break;
                }
            }); 
        }

        const deleteService = (id) => {

            let headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData.bearer}`
            }

            let newData = {
                id: id,
                partnerId: pid
            }
    
            let didGetCategories = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=PartnerServices/Delete`,"POST", headers, newData)
              didGetCategories.then((results) => 
                {
                switch(results.status){
                  case "500-Internal Server Error":
                    
                    break;
                  case "200-OK":
                    document.getElementById(`Service${id}`).remove();
                    break;
                  default:
                    
                    break;
                }
            }); 
        }

        React.useEffect(() => {

            let headers = {
                 Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.bearer}`
            }

            let didGetCategories = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Partner`,"GET", headers)
                didGetCategories.then((results) => 
                {
                switch(results.status){
                    case "500-Internal Server Error":
                        break;

                    case "200-OK":
                        //get data from token
                        changeId(results.data.id);
                        getServices(results.data.id);
                        break;

                    default:
                        break;
                    }
                }); 
            }
        , [])

        React.useEffect(() => {

            let headers = {
                 Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.bearer}`
            }

            let didGetCategories = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Categories`,"GET", headers)
                didGetCategories.then((results) => 
                {
                switch(results.status){
                    case "500-Internal Server Error":
                        break;

                    case "200-OK":
                        //get data from token
                        ChangeServiceCategories(results.data);
                        break;

                    default:
                        break;
                    }
                }); 
            }
        , [])
        
        return(
            <div className="ServiceContainer">
                <div style={{width: "100%", height: "30px"}}></div>
                {services && services.map((service) => (
                    <div id={`Service${service.id}`} key={service.id} className="ServiceOption">
                        <div style={{width: "100%", height: "60%", flexDirection: "column", paddingBottom: "30px"}}>
                            <div style={{margin: "10px 0px 10px 30px", color: "white",fontWeight: "bold",fontSize: "1.2rem",letterSpacing: "1px"}}>{service.subCategoryServiceId}</div>
                            <textarea id={`serviceBrief${service.id}`} style={{margin: "0px 15px 7px 25px", outline: "none",border: "none", borderBottom: "1px solid white", background: "none", width: "90%", height: "40%",color: "white",padding: "5px",resize: "none"}} defaultValue={service.brief}></textarea>
                            <div style={{flexDirection: "row", display: "flex",justifyContent: "center", width: "70%"}}>
                                <select id={`serviceDuration${service.id}`} className="SelectOptions" style={{marginRight: "10px"}} defaultValue={service.duration}>
                                    <option>30min</option>
                                    <option>1hr</option>
                                    <option>1hr 30min</option>
                                    <option>2hr</option>
                                    <option>2hr 30min</option>
                                    <option>3hr</option>
                                    <option>3hr 30min</option>
                                    <option>4hr</option>
                                    <option>4hr 30min</option>
                                    <option>5hr</option>
                                </select>
                                <span style={{marginRight: "10px", alignItems: "flex-end"}} className="InputOptions">$<input id={`serviceCost${service.id}`} style={{width: "60%", backgroundColor: "#8176FF", outline: "none", border: "none",color: "white"}} defaultValue={service.cost}></input></span>
                            </div>
                        </div>
                        <div style={{width: "40%", display: "flex",flexDirection: "column",alignItems: "center"}}>
                            <div onClick={() => deleteService(service.id)} className="DashboardDelete DashboardButton"><img src={trash} width="24px" height="24px"/></div>
                            <div onClick={() => tryUpdateService(service.id)} className="DashboardSave DashboardButton"><img src={save} width="24px" height="24px"/></div>
                        </div>
                    </div>))}
                    {services && (
                    <div className="ServiceOption">
                        {!create && (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                                <div onClick={() => changeCreate(true)} className="DashboardCreate DashboardButton"><img src={add} width="24px" height="24px"/></div>
                            </div>
                        )}
                        {create && (
                            <>
                                <div style={{width: "100%", height: "60%", flexDirection: "column", paddingBottom: "30px"}}>
                                    <select id="createCategory" className="SelectOptions" style={{margin: "0px 0px 7px 25px"}} onChange={getSubCategories}>
                                        {serviceCategories && (
                                            serviceCategories.map((category) => (<option key={category.id} value={category.id}>{category.name}</option>))
                                        )}
                                    </select>
                                    <select  id="createSubCategory" className="SelectOptions" style={{margin: "0px 5px 7px 25px"}}>
                                        {serviceSubCategories && (
                                            serviceSubCategories.map((subCategory) => (<option value={subCategory.id}>{subCategory.name}</option>))
                                        )}
                                    </select>
                                    <textarea id="createBrief" style={{margin: "0px 15px 7px 25px", outline: "none",border: "none", borderBottom: "1px solid white", background: "none", width: "90%", height: "40%",color: "white",padding: "5px",resize: "none"}}></textarea>
                                    <div style={{flexDirection: "row", display: "flex",justifyContent: "center", width: "70%"}}>
                                        <select id="createDuration" className="SelectOptions" style={{marginRight: "10px"}}>
                                            <option>30min</option>
                                            <option>1hr</option>
                                            <option>1hr 30min</option>
                                            <option>2hr</option>
                                            <option>2hr 30min</option>
                                            <option>3hr</option>
                                            <option>3hr 30min</option>
                                            <option>4hr</option>
                                            <option>4hr 30min</option>
                                            <option>5hr</option>
                                        </select>
                                        <span style={{marginRight: "10px", alignItems: "flex-end"}} className="InputOptions">$<input id="createCost" style={{width: "60%", backgroundColor: "#8176FF", outline: "none", border: "none",color: "white"}}></input></span>
                                    </div>
                                </div>
                                <div style={{width: "40%", display: "flex",flexDirection: "column",alignItems: "center"}}>
                                    <div onClick={() => tryCreateService()} className="DashboardCreate DashboardButton"><img src={add} width="24px" height="24px"/></div>
                                </div>
                            </>
                        )}
                    </div>
                    )}
                    {!services && (
                        <div style={{fontSize: "1.5rem",width: "100%", height: "80vh", display: "flex",justifyContent: "center",alignItems: "center",color: "white"}}>Loading...</div>
                    )}
                </div>)
            }


    const AccountOverview = () => {
        
        const [data, changeData] = useState();

        React.useEffect(() => {

            let headers = {
                 Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.bearer}`
        
            }

            let didGetCategories = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Partner`,"GET", headers)
                didGetCategories.then((results) => 
                {
                switch(results.status){
                    case "500-Internal Server Error":
                        
                        break;
                    case "200-OK":
                        //get data from token
                        changeData(results.data);
                        console.log(results.data);
                        break;
                    default:
                        
                        break;
                    }
                }); 
            }
        , [])

        const tryCreateStripeAccount = () => {
            let newData = {
                "refresh_url" : window.location.href,
                "return_url" : window.location.href
            }
            
    
            let headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization : `Bearer ${userData.bearer}`
            }

            let didCreateServices = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Stripe/Create`,"POST", headers,newData)
            didCreateServices.then((results) => 
                {
                console.log(results);
                switch(results.status){
                  case "500-Internal Server Error":
                    break;

                  case "200-OK":
                    window.location.replace(results.data);
                    break;

                  default:
                    break;
                }
            }); 
        }

        const tryCreateStripePayment = () => {
            let newData = {
                "plan" : "regular"
            }
    
            let headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization : `Bearer ${userData.bearer}`
            }

            let didCreateServices = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Stripe/Payment`,"POST", headers,newData)
            didCreateServices.then((results) => 
                {
                console.log(results);
                switch(results.status){
                  case "500-Internal Server Error":
                    break;

                  case "200-OK":
                    window.location.replace(results.data);
                    break;

                  default:
                    break;
                }
            }); 
        }

        const tryUpdatePartner = () => {

            let newData = {
            firstName: document.getElementById("firstName").value,
            middleName: document.getElementById("middleName").value,
            lastName: document.getElementById("lastName").value,
            about: document.getElementById("about").value,
            title: document.getElementById("title").value,
            email: document.getElementById("email").value,
            mobile: document.getElementById("mobile").value,
            userId: userData.user.id
            }
        
            let headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.bearer}`
            }
        
            let didUpdate = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Partner/Update`,"POST", headers,newData)
                didUpdate.then((results) => 
                {
                console.log(results);
            
                switch(results.status){
                    case "500-Internal Server Error":
                        break;
            
                    case "200-OK":
                        console.log(results);

                        break;
            
                    default:
                        break;
                }
            }); 
        }

        return (
            <div className="ServiceContainer">
                <div style={{margin: "3vh 0 3vh 0",display: "flex", flexDirection: "column", alignItems: "flex-start", alignSelf: "flex-start", width: "100%", minHeight: "80vh"}}>
                    {data && (
                    <>
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <div style={{padding: "30px 0px 30px 0px",width: "90%",borderTop: "solid 2px #fff", borderBottom: "solid 2px #fff", display: "flex", flexDirection: "column", background: "linear-gradient(32deg, rgba(104, 96, 208, 0.85) , rgb(11, 10, 23) 81.14%)"}}>
                                <div style={{fontSize: "1.5rem", color: "white", textDecoration: "underline", marginBottom: "30px", marginLeft: "2.5vw"}}>
                                    Personal information:
                                </div>
                                <div style={{position: "relative", margin: "0 0 33vh 2.5vw"}}>
                                    <label name="profileLabel" style={{cursor: "pointer",position: "absolute",top: "150px",left: "7%",zIndex: "3",display: "flex", justifyContent: "center", alignItems: "center",borderRadius: "150px", width: "8vw", height: "16vh", color: "white", textAlign: "center",border: "5px solid white"}} htmlFor="profilePhoto"><img src={rock} style={{zIndex: "2",display: "flex", justifyContent: "center", alignItems: "center",borderRadius: "150px", width: "8vw", height: "16vh", color: "white", textAlign: "center",border: "5px solid white"}}/></label>
                                    <ImageUpload inputId={"profilePhoto"} imageStyle={{position: "absolute",top: "150px",left: "7%",zIndex: "2",display: "flex", justifyContent: "center", alignItems: "center",borderRadius: "150px", width: "8vw", height: "16vh", color: "white", textAlign: "center",border: "5px solid white"}}/>
                                    <label name="bannerLabel" style={{position: "absolute", left: 0,cursor: "pointer",borderRadius: "20px", width: "35vw", height: "30vh", color: "white", textAlign: "center",zIndex: "1"}} htmlFor="bannerPhoto"><img alt="An arrow to signify uploading" style={{borderRadius: "20px", width: "32vw", height: "25vh", color: "white", textAlign: "center",zIndex: "1"}} width="60px" height="60px" src={sunflower}/></label>
                                    <ImageUpload inputId={"bannerPhoto"} imageStyle={{position: "absolute", left: 0,borderRadius: "20px", width: "32vw", height: "25vh", color: "white", textAlign: "center",zIndex: "0"}}/>
                                </div>
                                <div className="DashboardContainer">
                                    <label style={{color: "white",paddingBottom: "5px"}} htmlFor="title">Title:&nbsp;</label>
                                    <input id="title" defaultValue={data.title} style={{fontWeight: "bold", letterSpacing: ".7px",outline: "none", border: "none", background: "#6860D0",padding: "5px", borderRadius: "5px"}} className="AccountText"/>
                                </div>  
                                <div className="DashboardContainer">
                                    <label style={{color: "white",paddingBottom: "5px"}} htmlFor="firstName">Name:&nbsp;</label>
                                    <input id="firstName" defaultValue={data.firstName} className="AccountText" style={{width: "13%" ,background: "#6860D0",border: "none", outline: "none", padding: "5px", borderRadius: "5px", fontWeight: "bold", letterSpacing: ".7px"}}/>
                                    <input id="middleName" defaultValue={data.middleName} className="AccountText" style={{width: "13%",background: "#6860D0",border: "none", outline: "none", padding: "5px", borderRadius: "5px", fontWeight: "bold", letterSpacing: ".7px"}}/>
                                    <input id="lastName" defaultValue={data.lastName} className="AccountText" style={{width: "13%",background: "#6860D0",border: "none", outline: "none", padding: "5px", borderRadius: "5px", fontWeight: "bold", letterSpacing: ".7px"}}/>
                                </div>
                                <div className="DashboardContainer">
                                    <img style={{width: "18px", height: "18px", marginRight: "7px"}} src={phone}/><input id="mobile" style={{width: "120px",fontWeight: "bold", letterSpacing: ".7px",outline: "none", border: "none", background: "#6860D0",padding: "5px", borderRadius: "5px",color: "white"}} defaultValue={data.mobile}/> 
                                    <img style={{width: "18px", height: "18px",marginLeft: "10px",marginRight: "7px"}} src={email}/><input id="email" style={{width: "270px",fontWeight: "bold", letterSpacing: ".7px",outline: "none", border: "none", background: "#6860D0",padding: "5px", borderRadius: "5px",color: "white"}} defaultValue={data.email}/>
                                </div>
                                <div className="DashboardContainer">
                                    <label style={{color: "white",paddingBottom: "5px",alignSelf: "flex-start"}} htmlFor="businessName">About Section:&nbsp;</label>
                                    <textarea id="about" defaultValue={data.about} style={{width: "353px",height: "150px", resize: "none",fontWeight: "bold", letterSpacing: ".7px",outline: "none", border: "none", background: "#6860D0",padding: "5px", borderRadius: "5px"}} className="AccountText"/>
                                    <Button customStyle={{marginLeft: "10%",color: "white", width: "20%"}} OnClick={() => tryUpdatePartner()} Text="Update"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <div style={{padding: "30px 0px 30px 0px",width: "90%",borderTop: "solid 2px #fff", borderBottom: "solid 2px #fff", display: "flex", flexDirection: "column", background: "linear-gradient(32deg, rgba(104, 96, 208, 0.85) , rgb(11, 10, 23) 81.14%)"}}>
                                <div style={{fontSize: "1.5rem", color: "white", textDecoration: "underline", marginBottom: "30px", marginLeft: "2.5vw"}}>
                                    Payment information:
                                </div>
                                <div className="DashboardContainer">
                                    <label style={{color: "white",paddingBottom: "5px"}} htmlFor="title">Stripe Account:&nbsp;</label>
                                    <button onClick={() => tryCreateStripeAccount()} style={{cursor: "pointer",color: "black", backgroundColor: "#eddfdf", outline: "none", border: "none", padding: "15px", borderRadius: "15px"}}>Connect</button>
                                </div> 
                                <div className="DashboardContainer">
                                    <label style={{color: "white",paddingBottom: "5px"}} htmlFor="title">Stripe Plan:&nbsp;</label>
                                    <button onClick={() => tryCreateStripePayment()} style={{cursor: "pointer",color: "black", backgroundColor: "#eddfdf", outline: "none", border: "none", padding: "15px", borderRadius: "15px"}}>Connect</button>
                                </div>
                            </div>
                        </div>
                    </>
                    )}
                    {!data && (
                        <div style={{fontSize: "1.5rem",width: "100%", height: "80vh", display: "flex",justifyContent: "center",alignItems: "center",color: "white"}}>Loading...</div>
                    )}
                </div>
            </div>
        )
    }

    const [option, ChangeOption] = useState(<AllServices/>);

    return (
        <div style={{width: "100%", minHeight: "85vh",display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "column",position: "absolute", top: "0",minHeight: "100%",width: "20%", alignSelf: "flex-start", background: "linear-gradient(161.77deg, rgba(104, 96, 208, .85) -103.8%, #0B0A17 81.14%)", zIndex: "0", alignItems: "center"}}>
                <div style={{height: "7vh", width: "100%"}}></div>
                <div className="LayoutOption" onClick={() => ChangeOption(<AllServices/>)}><img style={{marginRight: "20px"}} width={32} height={32} src={services}/>Services</div>
                <div className="LayoutOption" onClick={() => ChangeOption(<AccountOverview/>)}><img style={{marginRight: "20px"}} width={32} height={32} src={profile}/>Profile</div>
                <Link onClick={() => props.data.Header.removeHeader(false)} to={"/"} style={{position: "absolute", bottom: "15%", textDecoration: "none"}} className="LayoutOption"><div className="LayoutOption"><img width={34} height={34} style={{marginRight: "20px"}} src={home}/>Home</div></Link>
                <div className="LayoutOption" style={{position: "absolute", bottom: "5%"}}><img width={32} height={32} style={{marginRight: "20px"}} src={logout}/>Logout</div>
            </div>
            {option}
        </div>
    );
}

export default Dashboard;
