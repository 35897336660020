import React from "react";

 async function apiRequest(url,methodType,headers,data){
        console.log(data);
        try{
            const res = await fetch(url, {
            method: methodType,
            headers: headers,
            body: JSON.stringify(data)
            })
            //if(!res.ok){
                //const message = `An error has occured: ${res.status} - ${res.statusText}`;
                //throw new Error(message);
            //}
            const newData = await res.json();
            const result = {
                status: res.status + "-" + res.statusText,
                headers: {
                  "Content-Type": res.headers.get("Content-Type"),
                  "Content-Length": res.headers.get("Content-Length"),
                },
                length: res.headers.get("Content-Length"),
                data: newData,
              };
              return result;
        } catch (e){
            console.log(e);
        }
    }

export default apiRequest;