import React from "react";
import "./App.css";
import Header from "./components/js/header";
import Home from "./components/js/home.js";
import Login from "./components/js/login.js";
import Partner from "./components/js/partner";
import { Routes, Route } from "react-router-dom";
import Path from "./components/js/path.js";
import Member from "./components/js/member";
import Marketplace from "./components/js/marketplace";
import Account from "./components/js/account";
import { useState } from "react";
import Dashboard from "./components/js/dashboard";

const App = () => {
	const [isAuth, changeAuth] = useState(false);
	const [token, changeToken] = useState({});
	const [hasHeader, removeHeader] = useState(false);
	const data = { Auth: { isAuth: isAuth, changeAuth: changeAuth }, Token: { token: token, changeToken: changeToken }, Header: { hasHeader: hasHeader, removeHeader: removeHeader } };
	const [home, changeHome] = useState(<Home data={data} />);
	React.useEffect(() => {
		data.Auth.isAuth ? changeHome(<Dashboard data={data} />) : changeHome(<Home data={data} />);
	}, [data.Auth.isAuth]);
	return (
		<div className="app-wrapper">
			{!hasHeader && <Header data={data} />}
			<Routes>
				<Route path={"/"} element={<Home data={data} />} />
				<Route path={"/login"} element={<Login data={data} />} />
				<Route path={"/account"} element={<Account />} />
				<Route path={"/partner"} element={<Partner data={data} />} />
				<Route path={"/marketplace"} element={<Marketplace />} />
				<Route path={"/path"}>
					<Route path="" element={<Path />} />
					<Route path="partner" element={<Partner data={data} />} />
					<Route path="member" element={<Member data={data} />} />
				</Route>
			</Routes>
		</div>
	);
};

export default App;
