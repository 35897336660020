import React from "react";
import "../css/service.css"

const Service = (props) => {

    const handleBackground = (e) => {
        if(e.target.validity.valid === true){
            e.target.style.border = "none";
            e.target.style.background = "none";
            e.target.classList.remove("ServiceError");
            e.target.classList.add("ServiceInput");
        }
    }

    const handleCostBackground = (e) => {
        if(e.target.validity.valid === true){
            e.target.style.border = "none";
            e.target.style.background = "none";
        }
    }

    return(
        <>
            <p hidden={true} disabled={true}>{props.serviceCategories}</p>
            <p style={{color: "white", fontSize: "1.7rem",margin: "20px 0px 30px 4vw"}}>{props.serviceTitle}</p>
            <input id={"ServiceTitle"+props.serviceNumber} hidden={true} disabled={true} value={props.serviceTitle} />
            <textarea id={"ServiceDescription"+props.serviceNumber} placeholder="Add a breif description" className="ServiceInput" defaultValue={props.defaultDescription} onChange={handleBackground} required={true}/>
            <div id={"ServiceSelects"} style={{marginLeft: "4vw",display: "flex", flexDirection: "row"}}>
                <select id={"ServiceSelectTime"+props.serviceNumber} className="ServiceSelect" style={{marginRight: "2vw"}} defaultValue={props.defaultTime}>
                    <option>30min</option>
                    <option>1hr</option>
                    <option>1hr 30min</option>
                    <option>2hr</option>
                    <option>2hr 30min</option>
                    <option>3hr</option>
                    <option>3hr 30min</option>
                    <option>4hr</option>
                    <option>4hr 30min</option>
                    <option>5hr</option>
                </select>
                <span id="ServiceSelectPriceContainer" className="ServiceSelect">$<input id={"ServiceSelectPrice"+props.serviceNumber} onChange={handleCostBackground} className="ServiceCost" defaultValue={props.defaultPrice} required={true}/></span>
            </div>
            <div style={{width: "110%", height: "2px", backgroundColor: "#8176FF",alignSelf: "center"}}/>
        </>
    );
}

export default Service;