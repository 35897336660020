import { useState,useEffect} from "react"

export const ImageUpload = (props) => {
    const [selectedFile, setSelectedFile] = useState(props.defaultPreview)
    const [preview, setPreview] = useState()

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        let tempArray = props.inputId.split("P");
        document.getElementsByName(`${tempArray[0]}Label`)[0].style.opacity = 0;

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            let tempArray = props.inputId.split("P");
            document.getElementsByName(`${tempArray[0]}Label`)[0].style.opacity = 100;
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    return (
        <>
            <input id={props.inputId} accept="image/*" style={{display: "none"}} type='file' onChange={onSelectFile} required={true}/>
            {selectedFile &&  <img style={props.imageStyle} src={preview} />}
        </>
    )
}