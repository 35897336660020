import React from "react";
import "../css/formBase.css";
import ParticleBackground from "./particleBackground";

const FormBase = (props) => {
	return (
		<div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
			<div className="FormBaseScreen">{props.formContent}</div>
			<ParticleBackground />
		</div>
	);
};

export default FormBase;
