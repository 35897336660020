import checkMark from "../imgs/checkMark.svg"

const CheckMark = () => {
 return(
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "15vw", height: "15vw"}}>
        <div className="FinishAnimation" style={{ backgroundColor: "white", width: "30px", height: "30px",borderRadius: "50%",display:"flex", justifyContent: "center", alignItems: "center"}}>
                <img className="CheckAnimation" src={checkMark}/>
        </div>
    </div>)
}

export default CheckMark