import "../css/header.css";
import React from "react";
import headerTag from "../imgs/headerTag.png";
import { Link } from "react-router-dom";
import apiRequest from "../js/api.js";
import { useState, useEffect } from "react";
import logo from "../imgs/logobg.png";

const Header = (props) => {
	const [loggedIn, changeLoggedIn] = useState(false);
	let data = JSON.parse(window.localStorage.getItem("porteiro_user_token"));

	useEffect(() => {
		data = JSON.parse(window.localStorage.getItem("porteiro_user_token"));
		if (data != undefined || null) {
			changeLoggedIn(true);
		}
	}, [props.data.Auth.isAuth]);

	const trylogOut = (data) => {
		let newData = {
			userId: data.user.id,
			token: data.token,
		};

		let headers = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${data.bearer}`,
		};

		let didLogout = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Logout`, "POST", headers, newData);
		didLogout.then((results) => {
			switch (results.status) {
				case "500-Internal Server Error":
					console.log("error");
					break;
				case "200-OK":
					window.localStorage.removeItem("porteiro_user_token");
					changeLoggedIn(false);

					break;
				default:
					break;
			}
		});
		/*data.Auth.changeAuth(false);
        data.Token.changeToken({});*/
	};

	return (
		<>
			<div className="header-container">
				<div style={{ color: "white", width: "50vw" }}>
					<img className="full-logo" alt="The Porteiro Logo" width={150} height={31} src={headerTag} />
					<img className="partial-logo" alt="The Porteiro Logo" height={31} src={logo} />
				</div>
				<div style={{ color: "white", display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "50vw", alignItems: "center" }}>
					<Link style={{ textDecoration: "none", color: "white" }} to={"/"}>
						<div className="NavTag">HOME</div>
					</Link>
					<a style={{ textDecoration: "none", color: "white" }} rel="noreferrer" target="_blank" href={"https://porteiro.com/"}>
						<div className="NavTag">ABOUT</div>
					</a>
					{loggedIn && (
						<a style={{ textDecoration: 'none', color: 'white' }} rel="noreferrer" href={"./dashboard"}>
							<div className="NavTag">DASHBOARD</div>
						</a>
					)}
					{loggedIn && (
						<Link onClick={() => trylogOut(data)} style={{ textDecoration: "none", color: "white" }} to={"/"}>
							<div className="NavTag NavTagLogin">LOGOUT</div>
						</Link>
					)}
					{!loggedIn && (
						<Link style={{ textDecoration: "none", color: "white" }} to={"/login"}>
							<div className="NavTag NavTagLogin">LOG IN</div>
						</Link>
					)}
				</div>
			</div>
		</>
	);
};

export default Header;
