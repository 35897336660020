import React from "react";
import "../css/marketplace.css"
import Magnifier from "../imgs/magnifier.png"
import sphere from "../imgs/sphere.png"
import arch from "../imgs/arch.png"
import archTwo from "../imgs/arch2.png"

const Marketplace = () => {

    const getData = () => {
        return([
            {background: "https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/article5874.jpg",header: "Beauty and Personal Care"},
            {background: "https://media.cntraveler.com/photos/53e2f41cdddaa35c30f66775/master/pass/highway-traffic.jpg",header: "Automotive and Transportation"},
            {background: "https://i.scdn.co/image/9effaa1fae5b51804692a309651083437c8f29fd",header: "Entertainment and Events"},
            {background: "https://images.unsplash.com/photo-1600891964599-f61ba0e24092?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cmVzdGF1cmFudCUyMGZvb2R8ZW58MHx8MHx8&w=1000&q=80",header: "Food and Beverage"},
            {background: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJgr_zLaECCUl4ThjMqSJQabFgpclA_8oxhA&usqp=CAU",header: "Health and Wellness"},
            {background: "https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/welcome-new-img.jpg",header: "Home and Garden"},
            {background: "https://www.rover.com/blog/wp-content/uploads/russell-terrier-in-bed-cozy-960x540.jpg",header: "Pets and Animals"},
            {background: "https://ecm.capitalone.com/WCM/small-business-bank/imagesets/home-page-grid-1/rmobile.jpg",header: "Bussines and Financial"}
        ])
    }

    const getCategories = () => {

        const data = [];
        const categories = getData();
        categories.forEach(category => {
            data.push(
                <div onClick={() => alert(category.header)} style={{cursor: "pointer",borderRadius: "10px",margin: "10px 30px 20px 10px",width: "200px",height: "200px", backgroundImage: `url(${category.background})`,backgroundSize: "cover"}}>
                    <div style={{borderRadius: "10px",height: "100%", width: "100%",backdropFilter: "blur(3px) brightness(60%)",display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <h3 style={{textAlign: "center"}}>{category.header}</h3>
                    </div>
                </div>
            )})

        return(data)
    }

    return (
    <>
        <div className="Marketplace">
            <img alt="" style={{position: "absolute",right: "-3%", top: "-18%", width: "27%", height:"43%",zIndex: "-1",opacity: "5%"}} src={sphere}/>
            <img alt="" style={{position: "absolute",left: "0%", bottom: "0%", width: "28%", height:"60%",zIndex: "-1",opacity: "10%"}} src={arch}/>
            <img alt="" style={{position: "absolute",left: "55%", bottom: "0%", width: "25%", height:"28%",zIndex: "-1",opacity: "10%"}} src={archTwo}/>
            <div style={{width: "65%", display: "flex", flexDirection: "column", alignItems: "center", zIndex: "1"}}>
                <div style={{width: "90%", display: "flex", flexDirection: "column",alignItems: "center",marginBottom: "15px"}}>
                    <h4  className="FadeInDiv" style={{letterSpacing: "1.5px", fontSize: "2.5rem", fontFamily: "Segoe UI", fontWeight: "bold", margin: "0px"}}>Search and Discover</h4>
                    <input style={{marginTop: "30px",width: "45%",padding:"15px 15px 15px 40px",backgroundPosition: "8px 12px",backgroundImage: `url(${Magnifier})`,backgroundRepeat: "no-repeat",borderRadius: "7px",outline: "none",border: "none"}} placeholder="Search"></input>
                </div>
                <div style={{display: "flex", width: "100%",flexDirection: "column",alignItems: "center"}}>
                    <h4 className="FadeInDiv" style={{width: "92%",letterSpacing: "2px", fontSize: "2rem", fontFamily: "Segoe UI", fontWeight: "bold", margin: "0px"}}>Categories</h4>
                    <div style={{display: "flex", flexDirection:"row", flexWrap: "wrap", width: "100%",justifyContent: "center"}}>
                        {getCategories()}
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default Marketplace; 