const usStates = [
    {
        name: "Alabama",
        abreviation: "AL"
    },
    {
        name: "Alaska",
        abreviation: "AK"
    },
    {
        name: "Arizona",
        abreviation: "AZ"
    },
    {
        name: "Arkansas",
        abreviation: "AR"
    },
    {
        name: "California",
        abreviation: "CA"
    },
    {
        name: "Colorado",
        abreviation: "CO"
    },
    {
        name: "Connecticut",
        abreviation: "CT"
    },
    {
        name: "Delaware",
        abreviation: "DE"
    },
    {
        name: "Florida",
        abreviation: "FL"
    },
    {
        name: "Georgia",
        abreviation: "GA"
    },
    {
        name: "Hawaii",
        abreviation: "HI"
    },
    {
        name: "Idaho",
        abreviation: "ID"
    },
    {
        name: "Illinois",
        abreviation: "IL"
    },
    {
        name: "Indiana",
        abreviation: "ID"
    },
    {
        name: "Iowa",
        abreviation: "IA"
    },
    {
        name: "Kansas",
        abreviation: "KS"
    },
    {
        name: "Kentucky",
        abreviation: "KY"
    },
    {
        name: "Louisiana",
        abreviation: "LA"
    },
    {
        name: "Maine",
        abreviation: "ME"
    },
    {
        name: "Maryland",
        abreviation: "MD"
    },
    {
        name: "Massachusetts",
        abreviation: "MA"
    },
    {
        name: "Michigan",
        abreviation: "MI"
    },
    {
        name: "mississippi",
        abreviation: "MS"
    },
    {
        name: "Missouri",
        abreviation: "MO"
    },
    {
        name: "Montana",
        abreviation: "MT"
    },
    {
        name: "Nebraska",
        abreviation: "NE"
    },
    {
        name: "Nevada",
        abreviation: "NV"
    },
    {
        name: "New Hampshire",
        abreviation: "NH"
    },
    {
        name: "New Jersey",
        abreviation: "NJ"
    },
    {
        name: "New Mexico",
        abreviation: "NM"
    },
    {
        name: "New York",
        abreviation: "NY"
    },
    {
        name: "North Carolina",
        abreviation: "NC"
    },
    {
        name: "North Dakota",
        abreviation: "ND"
    },
    {
        name: "Ohio",
        abreviation: "OH"
    },
    {
        name: "Oklahoma",
        abreviation: "OK"
    },
    {
        name: "Oregon",
        abreviation: "OR"
    },
    {
        name: "Pennsylvania",
        abreviation: "PA"
    },
    {
        name: "Rhode Island",
        abreviation: "RI"
    },
    {
        name: "South Carolina",
        abreviation: "SC",
    },
    {
        name: "South Dakota",
        abreviation: "SD"
    },
    {
        name: "Tennessee",
        abreviation: "TN"
    },
    {
        name: "Texas",
        abreviation: "TX"
    },
    {
        name: "Utah",
        abreviation: "UT"
    },
    {
        name: "Vermont",
        abreviation: "VT"
    },
    {
        name: "Virgina",
        abreviation: "VA"
    },
    {
        name: "Washington",
        abreviation: "WA"
    },
    {
        name: "West Virgina",
        abreviation: "WV"
    },
    {
        name: "Wisconsin",
        abreviation: "WI"
    },
    {
        name: "Wyoming",
        abreviation: "WY"
    }
]

export default usStates;