import React from "react";
import "../css/member.css";
import FormBase from "./formBase";
import logo from "../imgs/logobg.png";
import { useState } from "react";
import { useRef } from "react";
import upload from "../imgs/upload.png";
import { Link } from "react-router-dom";
import { ImageUpload } from "./imageUpload";
import CheckMark from "./CheckMark";
import Button from "./button";
import { ProgressBar, Step } from "react-step-progress-bar";
import check from "../imgs/checkMark.svg";
import apiRequest from "./api.js";
const Member = (props) => {
	const authData = { ...props };
	const ref = useRef(null);
	var currentData = {};
	const validPassword = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");

	const tryCreateMember = (nextStep, errorHandler) => {
		let newData = {
			mobile: currentData.mobile,
			email: currentData.email,
			password: currentData.password,
		};

		let headers = {
			Accept: "application/json",
			"Content-Type": "application/json",
		};

		let didCreate = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Member/Register`, "POST", headers, newData);
		didCreate.then((results) => {
			console.log(results);
			switch (results?.status) {
				case "200-OK":
					tryLogin(nextStep, errorHandler);
					break;

				default:
					errorHandler(results.data);
					break;
			}
		});
	};

	const tryLogin = (nextStep, errorHandler) => {
		let newData = {
			identifier: currentData.mobile,
			password: currentData.password,
			provider: 1,
		};
		let headers = {
			Accept: "application/json",
			"Content-Type": "application/json",
		};
		let didLogin = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Login`, "POST", headers, newData);
		didLogin.then((results) => {
			switch (results.status) {
				case "200-OK":
					//get data from token
					window.localStorage.setItem("porteiro_user_token", JSON.stringify(results.data));
					authData.data.Auth.changeAuth(true);
					changeStep(nextStep);
					break;

				default:
					errorHandler(results.data);
					break;
			}
		});
	};

	const tryUpdateMember = (nextStep, errorHandler) => {
		const userData = JSON.parse(window.localStorage.getItem("porteiro_user_token"));
		let newData = {
			firstName: currentData.firstName,
			lastName: currentData.lastName,
			about: currentData.about,
			userId: userData.user.id,
		};
		let headers = {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${userData.bearer}`,
		};
		let didUpdate = apiRequest(`${process.env.REACT_APP_API_URL}/1/index.php?url=Account/Member/Update`, "POST", headers, newData);
		didUpdate.then((results) => {
			switch (results.status) {
				case "200-OK":
					changeStep(nextStep);
					break;

				default:
					errorHandler("Something went wrong");
					return false;
			}
		});
	};

	const handleChange = (nextStep, errorHandler) => {
		let valid = true;
		ref.current.childNodes.forEach((node) => {
			valueCheck: if (node.id !== "" && node.id !== "photos") {
				if (node.validity.valid === false) {
					document.getElementById(node.id).style.backgroundColor = "#FFCCCC";
					document.getElementById(node.id).style.border = "#bd1616 2px solid";
					valid = false;
					break valueCheck;
				}
				if (node.id === "password" || node.id === "confirmPassword") {
					let test = validate();
					if (test === false) {
						document.getElementById(node.id).style.backgroundColor = "#FFCCCC";
						document.getElementById(node.id).style.border = "#bd1616 2px solid";
						valid = false;
						break valueCheck;
					}
				}
				console.log([node.id, node.value]);
				currentData[node.id] = node.value;
			} else if (node.id === "photos") {
				node.childNodes.forEach((photoNode) => {
					photoCheck: if (photoNode.id !== "") {
						if (photoNode.validity.valid === false && currentData[photoNode.id] === undefined) {
							if (valid === true) {
								alert("Please provide both photos");
							}
							valid = false;
							break photoCheck;
						} else if (photoNode.value === "") {
							break photoCheck;
						}
						console.log([photoNode.id, photoNode.files[0]]);
						currentData[photoNode.id] = photoNode.files[0];
					}
				});
			}
		});
		console.log(currentData);
		if (valid === true) {
			if (nextStep.props.name === "stepTwo") {
				tryCreateMember(nextStep, errorHandler);
			}
			if (nextStep.props.name === "stepThree") {
				tryUpdateMember(nextStep, errorHandler);
			}
		}
	};

	const handleBackground = (e) => {
		if (e.target.validity.valid === true) {
			e.target.style.background = "#ffffff";
			e.target.style.border = "none";
		}
	};

	const validate = () => {
		let password = document.getElementById("password").value;
		let confirmPassword = document.getElementById("confirmPassword").value;
		console.log(validPassword.test(password));
		if (validPassword.test(password) && password === confirmPassword && password !== "" && confirmPassword !== "") {
			return true;
		}
		return false;
	};

	var StepThree = () => {
		return (
			<div key={10} id="stepTen" className="PartnerAnimaiton" style={{ width: "80%", height: "80%", margin: "50px", display: "flex", alignItems: "center", flexDirection: "column" }}>
				<CheckMark />
				<p className="FadeInAnimation" style={{ color: "white", fontSize: "2rem", fontWeight: "bold", marginBottom: "0px" }}>
					You did it!
				</p>
				<Link to={"/porteiro/"} style={{ textDecoration: "none", textAlign: "center" }} className="FormButton">
					Continue
				</Link>
			</div>
		);
	};

	var StepTwo = () => {
		return (
			<div key={6} id="stepSix" className="PartnerAnimaiton stepWrapper">
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%" }}>
					<img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{ marginBottom: "15px" }} width={"14%"} height={"50%"} src={logo} className="logo" />
					<div style={{ position: "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px" }}>
						<ProgressBar percent={50} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
							<Step>
								{() => (
									<div
										style={{
											width: "50px",
											height: "50px",
											position: "absolute",
											top: "-25px",
											left: "0%",
											backgroundColor: "#7E74FF",
											borderRadius: "50%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "1.7rem",
										}}
									>
										<img src={check} style={{ filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)" }} />
									</div>
								)}
							</Step>
							<Step>
								{() => (
									<div
										style={{
											width: "50px",
											height: "50px",
											position: "absolute",
											top: "-25px",
											left: "85%",
											backgroundColor: "rgb(207, 207, 207)",
											borderRadius: "50%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "1.7rem",
										}}
									>
										2
									</div>
								)}
							</Step>
						</ProgressBar>
					</div>
					<p className="smallTextOverride" style={{ color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px", letterSpacing: "1px", textAlign: "center" }}>
						Tell everyone a little more about yourself
					</p>
					<p className="smallerTextOverride" style={{ color: "#FFFFFF7f", fontSize: ".9vw", textAlign: "center" }}>
						Remember your future clients will see this information!
					</p>
				</div>
				<div ref={ref} style={{ display: "flex", flexDirection: "column", width: "100%", height: "60%", justifyContent: "space-between", alignItems: "center" }}>
					<input className="FormInput" id="firstName" type={"text"} placeholder={"First Name*"} defaultValue={""} onChange={handleBackground} required={true} />
					<input className="FormInput" id="lastName" type={"text"} placeholder={"Last Name*"} defaultValue={""} onChange={handleBackground} required={true} />
					<textarea maxLength={255} className="FormInput" id="about" type="text" style={{ resize: "none", height: "15vh" }} placeholder={"Tell us a bit about yourself"} defaultValue={""} />
					<Button
						customStyle={{ width: "60%" }}
						OnClick={() => {
							handleChange(<StepThree />);
						}}
						Text="Next"
					/>
				</div>
			</div>
		);
	};

	/*const StepTwo = (props) => {
        return (
        <div key={5} id="stepFive" className="PartnerAnimaiton" style={{width: "80%", height: "80%", margin: "50px"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%"}}>
                <img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{marginBottom: "15px"}} width={"14%"} height={"50%"} src={logo}/>
                <div style={{position : "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px"}}>
                    <ProgressBar percent={28} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "0%", backgroundColor: "#7E74FF",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             <img src={check} style={{filter: "invert(100%) sepia(0%) saturate(7487%) hue-rotate(225deg) brightness(110%) contrast(103%)"}}/>
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                             style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "43%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                             2
                         </div>
                            )}
                        </Step>
                        <Step>{() => (
                             <div
                                style={{width: "50px", height: "50px", position: "absolute", top: "-25px", left: "85%", backgroundColor: "rgb(207, 207, 207)",borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.7rem"}}>
                                3
                            </div>
                            )}
                        </Step>
                    </ProgressBar>
                </div>
                <p style={{color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px",letterSpacing: "1px",textAlign: "center"}}>Welcome!<br/>Let's customize your account</p>
                <p style={{color: "#FFFFFF7f", fontSize: ".9vw", textAlign: "center"}}>Upload a profile picture and banner</p>
            </div>
            <div ref={ref} style={{display: "flex", flexDirection: "row",flexWrap: "wrap", width: "100%", height: "60%", justifyContent:"center",alignItems: "center"}}>
                <div id="photos" style={{width: "98%",height: "30%",position: "relative", display: "flex", justifyContent: "center"}}>
                    <label  name="profileLabel" style={{cursor: "pointer",position: "absolute",top:"5%",left: "12%",zIndex: "3",display: "flex", justifyContent: "center", alignItems: "center",backgroundColor: "#e1d4d4", borderRadius: "150px", width: "11vw", height: "22vh", color: "white", textAlign: "center"}} htmlFor="profilePhoto"><img alt="An arrow to signify uploading" width="60px" height="60px" src={upload}/></label>
                    <ImageUpload defaultPreview={currentData.profilePhoto} inputId={"profilePhoto"} imageStyle={{position: "absolute",top:"5%",left: "12%",zIndex: "2",display: "flex", justifyContent: "center", alignItems: "center",borderRadius: "150px", width: "11vw", height: "22vh", color: "white", textAlign: "center"}}/>
                    <label name="bannerLabel" style={{cursor: "pointer",display: "flex", justifyContent: "flex-end", alignItems: "center",backgroundColor: "grey", borderRadius: "20px", width: "30vw", height: "22vh", color: "white", textAlign: "center",zIndex: "1",padding: "10px"}} htmlFor="bannerPhoto"><img alt="An arrow to signify uploading" style={{marginRight: "4vw"}} width="60px" height="60px" src={upload}/></label>
                    <ImageUpload defaultPreview={currentData.bannerPhoto} inputId={"bannerPhoto"} imageStyle={{position: "absolute",borderRadius: "20px", width: "32vw", height: "25vh", color: "white", textAlign: "center",zIndex: "0"}}/>
                </div>
                <div style={{width: "31.5vw"}} className="ButtonContainer">
                    <Button customStyle={{width: "35%"}} OnClick={() => {goBack(<StepOne/>)}} Text="Back"/>
                    <Button customStyle={{width: "35%"}} OnClick={() => {handleChange(<StepThree/>)}} Text="Next"/>
                </div>
            </div>
        </div>
    )};
    */

	var StepOne = () => {
		const [errorMessage, AddErrorMessage] = useState("");
		return (
			<div key={1} id="stepOne" className="PartnerAnimaiton stepWrapper">
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "40%" }}>
					<img alt={"A logo for porteiro, featuring a circle cut four ways making a diamond in the center"} style={{ marginBottom: "15px" }} width={"14%"} height={"50%"} src={logo} className="logo" />
					<div style={{ position: "relative", width: "80%", height: "10px", display: "flex", flexDirection: "row", justifyContent: "center", margin: "30px 0px 30px 0px" }}>
						<ProgressBar percent={0} filledBackground="#7E74FF" unfilledBackground="rgb(100, 100, 100)">
							<Step>
								{() => (
									<div
										style={{
											width: "50px",
											height: "50px",
											position: "absolute",
											top: "-25px",
											left: "0%",
											backgroundColor: "rgb(207, 207, 207)",
											borderRadius: "50%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "1.7rem",
										}}
									>
										1
									</div>
								)}
							</Step>
							<Step>
								{() => (
									<div
										style={{
											width: "50px",
											height: "50px",
											position: "absolute",
											top: "-25px",
											left: "90%",
											backgroundColor: "rgb(207, 207, 207)",
											borderRadius: "50%",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "1.7rem",
										}}
									>
										2
									</div>
								)}
							</Step>
						</ProgressBar>
					</div>
					<p className="smallTextOverride" style={{ color: "white", fontSize: "2vw", fontWeight: "bold", margin: "0px", letterSpacing: "1px" }}>
						Welcome! First things first...
					</p>
					<p className="smallerTextOverride" style={{ color: "#FFFFFF7f", fontSize: ".9vw" }}>
						You can always change them later.
					</p>
				</div>
				{errorMessage && <div style={{ color: "white" }}>{errorMessage}</div>}
				<div ref={ref} style={{ display: "flex", flexDirection: "column", width: "100%", height: "60%", justifyContent: "space-between", alignItems: "center" }}>
					<input className="FormInput" id="mobile" type={"mobile"} placeholder={"Mobile*"} defaultValue={currentData.mobile} onChange={handleBackground} required={true} />
					<input className="FormInput" id="email" type={"email"} placeholder={"Email*"} defaultValue={currentData.email} onChange={handleBackground} required={true} />
					<input className="FormInput" id="password" type={"password"} placeholder={"Password*"} defaultValue={currentData.password} onChange={handleBackground} required={true} />
					<input className="FormInput" id="confirmPassword" type={"password"} placeholder={"Confirm Password*"} defaultValue={currentData.confirmPassword} onChange={handleBackground} required={true} />
					<Button
						customStyle={{ width: "60%" }}
						OnClick={() => {
							handleChange(<StepTwo name="stepTwo" />, AddErrorMessage);
						}}
						Text="Next"
					/>
				</div>
			</div>
		);
	};

	const [step, changeStep] = useState(<StepOne name="stepOne" />);

	return (
		<>
			<FormBase formContent={step} />
		</>
	);
};
export default Member;
