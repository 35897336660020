import React from "react";
import "../css/path.css";
import ParticleBackground from "./particleBackground";
import headshot from "../imgs/headshot.png";
import hearthand from "../imgs/hearthand.png";
import { Link } from "react-router-dom";

const Path = () => {
	return (
		<>
			<div className="PathScreen">
				<div
					style={{ marginBottom: "5%", width: "100%", height: "30%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white", fontSize: "1.5rem", letterSpacing: "1px", textAlign: "center" }}
				>
					<h3 className="heading">How are you planning to use PORTEIRO?</h3>
					<p style={{ margin: "0px", fontSize: ".8rem" }}>We'll streamline your setup experience accordingly.</p>
				</div>
				<div className="path-options">
					<a className="PathOption" href={"https://porteiro.dadenllc.com/signup"} target={"_blank"}>
						<img style={{ width: "24%", objectFit: "contain" }} src={headshot} />
						<div className="path-option-text-wrapper">
							<p className="path-option-title">Member</p>
							<p className="path-option-text">
								<strong>Busy Professionals and Individuals</strong> who want to save effort and time, “Simplifying Your Lifestyle” of finding and buying personalized services.
							</p>
						</div>
					</a>
					<Link className="PathOption" to="partner">
						<img style={{ width: "24%", objectFit: "contain" }} src={hearthand} />
						<div className="path-option-text-wrapper">
							<p className="path-option-title">Partner</p>
							<p className="path-option-text">
								<strong>Solopreneurs</strong> who provide mobile personal care services and want to spend more time delivering services and less time and money managing bookings and scheduling services, payments, and analyzing sales performance.
							</p>
						</div>
					</Link>
				</div>
			</div>
			<ParticleBackground />
		</>
	);
};

export default Path;
